import React from 'react';
import '../styles/card.scss';
import TRADICIONAL from '../img/TRADICIONAL.png';
import TALCO from '../img/02.png';
import CAMPO from '../img/03.png';
import IVY from '../img/04.png';
import FLORAL from '../img/05.png';
import SUAVE from '../img/06.png';
import SOFT from '../img/am.png';
import CLEAN from '../img/sab1.png';
import ALVITEX from '../img/alv1.png';
import TEXCLEAN from '../img/tex1.png';
import POLICAR from '../img/policar.png';
import POLIGRAX from '../img/poligrax.png';
import POLIBRIL from '../img/polibril.png';
import POLIBRILHO from '../img/cera.png';
import SILIBRILHO from '../img/sili.png';
import PRETINHO from '../img/prtinho.png';
import CHEIRO from '../img/cheiro.png';
import DETER from '../img/d1.png'
import DETERSOFT from '../img/d2.png'
import LIMPE from '../img/l1.png'
import LIMPESOFT from '../img/l2.png'
import GEL from '../img/g1.png'
import GELSOFT from '../img/g2.png'

export type CardProps = {
    direction:string;
    productImg: string;
    productName: string;
    productDescription:string;
    productDilution:string;
}

function imgSelect(name:string){
    switch(name){   
        case'01': return TRADICIONAL 
        case'05': return TALCO 
        case'03': return CAMPO 
        case'02': return IVY 
        case'04': return FLORAL 
        case'06': return SUAVE 
        case'SOFT': return SOFT 
        case'CLEAN': return CLEAN 
        case'ALVITEX': return ALVITEX 
        case'TEXCLEAN': return TEXCLEAN 
        case'POLICAR': return POLICAR 
        case'POLIGRAX': return POLIGRAX 
        case'POLIBRIL': return POLIBRIL 
        case'POLIBRILHO': return POLIBRILHO 
        case'SILI': return SILIBRILHO 
        case'PRETINHO': return PRETINHO 
        case'CHEIRO': return CHEIRO 

        case'DETER': return DETER 
        case'DETERSOFT': return DETERSOFT 
        case'LIMPE': return LIMPE 
        case'LIMPESOFT': return LIMPESOFT 
        case'GEL': return GEL
        case'GELSOFT': return GELSOFT
    }
}

export function Card(props:CardProps){
    
    
    const img = imgSelect(props.productImg);
    
    return(
        <div className={`card ${props.direction}`} >    
            <img src={img} className="cardImage img-fluid" alt={props.productName}/>
                <div className="cardContent"> 
                  <h2 className="cardTitle">{props.productName}</h2>
                  <p className="cardBody">{props.productDescription}</p>
                  <ol dangerouslySetInnerHTML={{"__html":props.productDilution}}></ol>
                 {// <a href="#" className="catalogo">Veja mais</a>}
                    }
                </div>
        </div>
    );
}