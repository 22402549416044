import '../styles/menu.scss';
import { Link } from 'react-router-dom';
import { useState } from 'react';


  //
function handleClick(){
    let show
    const head = document.querySelector(".head");
    const menuToggle = document.querySelector(".menu-toggle");
    
    head?.classList.toggle("turnOn",show)
    document.body.style.overflow = show ? 'hidden': 'initial';
}


export function Menu(){

 
    return(
    <nav className="head">
        <div className="menu-toggle" onClick={handleClick} >
            <div className="one"></div>
            <div className="two"></div>
            <div className="three"></div>
        </div>
        
        <ul className="menu-nav">
            <li><Link to="/" data-item='Inicio'>Início</Link></li>
            <li><Link to="/desinfetantes" data-item='Desinfetantes'>Desinfetantes</Link></li>
            <li><Link to="/detergentes" data-item='Detergentes'>Detergentes</Link></li>
            <li><Link to="/lavanderia" data-item='Lavanderia'>Lavanderia</Link></li>
            <li><Link to="/automotivo" data-item='Automotivo'>Automotivo</Link></li>
        </ul>
    </nav>
    );
}