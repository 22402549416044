import '../styles/home.scss'
import { Link } from 'react-router-dom';

import img1 from '../img/03.png';
import img2 from '../img/04.png';
import img3 from '../img/TRADICIONAL.png';
import logo from '../img/Logo-01.svg';
import whatsappImg from '../img/whatsapp.svg';
import instagramImg from '../img/instagram.svg';
import facebookImg from '../img/facebook.svg';



export function Home(){
    return(
        <main className="container-fluid">
            <div className="row h-100">
                <section className="col-3">
                    <img src={img1} className="img-fluid back2" alt=""/>
                    <img src={img2} className="img-fluid back" alt=""/>
                    <img src={img3} className="img-fluid " alt=""/>
                    <div id="Transition"></div>
                </section>
                
                <section className="col-8">
                    <div id="homeContainer">
                        <header>
                            <img src={logo} className="navbar-brand" alt="Logo Pro Limpe" width="500"/>
                        </header>
                        <h2 className="display-6 fw-normal">
                            Fabricamos produtos saneantes para diversos setores e negócios
                        </h2>
                        <p className="lead fw-normal">
                           Somos especializados na fabricação de produtos para limpeza profissional, lavanderia e polimento.
                           Todos os nossos produtos atendem às normas brasileiras e são registrados na Anvisa.
                        </p>
                        <Link to="/desinfetantes" id="catalogo" className="btn" role="button">Confira nosso catálogo</Link>
                        <footer className="container">
                            <div className="row" >
                                <div className="col-6 ">
                                    <h6>Redes Sociais</h6> 
                                    <div className="footerHR" ></div>
                                        <div className="icones">
                                            <a href="https://www.facebook.com/evandroprolimpe/" target="_blank"><img src={facebookImg} className="img-fluid icons" alt="facebook link"/></a>
                                            <a href="https://www.instagram.com/prolimpebrasil/" target="_blank"><img src={instagramImg} className="img-fluid icons" alt="instagram link"/></a>
                                            <a href="https://api.whatsapp.com/send?phone=5527999995952&text=Ol%C3%A1%2C%20gostaria%20de%20solicitar%20um%20or%C3%A7amento." target="_blank"><img src={whatsappImg} className="img-fluid icons" alt="whatsapp link"/></a>
                                        </div>
                                    </div>
                                    <div className="col-6 ">
                                        <h6>Telefones</h6> 
                                        <div className="footerHR" ></div>
                                        <ol>
                                            <li>(27) 99999 - 5952</li>
                                            <li>(27) 99856 - 3322</li>
                                            <li>(27) 3218 - 2991</li>
                                        </ol>
                                    
                                    
                                </div>  
                            </div>
                            <div className="row">
                                <div className="col-12">
                                <h6>Nosa localização</h6>
                                <p>Rua Jardeu, 301 - Nova Zelândia, Serra – ES – CEP: 29175-714</p> 
                                </div>
                            </div>
                            <p>© 2021 Desenvolvido pela Pro Limpe - ME. ❤</p> 
                        </footer>
                    </div>
                </section>
            </div>
            
        </main>
        
    );
}