import { CardProps } from '../componets/Card';
import { Menu } from '../componets/Menu';
import { ProductDisplayer } from '../componets/ProductDisplayer';
import allProducts from '../componets/products.json';
import '../styles/main.scss';
import img from '../img/carwash.jpg'
import whatsappImg from '../img/whatsapp.svg';
import product from '../img/prtinho.png'

export function Automotivo(){
    
    return(
        <div className="container2">
            <img src={img} className="background-img" alt="Backgroind" />
            <Menu />
            <div className="header">
                <div>
                    <h1>Linha Automotiva</h1>
                    <p>Produtos altamente concentrados que oferecem uma excelente relação custo x benefício</p>
                    <a href="https://api.whatsapp.com/send?phone=5527999995952&text=Ol%C3%A1%2C%20gostaria%20de%20solicitar%20um%20or%C3%A7amento." target="_blank" className="catalogo"><img src={whatsappImg} className="img-fluid icons" alt="whatsapp link"/> Peça um orçamento</a>
                </div>
                <img src={product} alt="" />
            </div>
            <ProductDisplayer products={allProducts.automotivo} />
        </div>
    );
}