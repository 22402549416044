
import '../styles/productDisplayer.scss';
import { Card } from './Card';
type CardProps = {
    productImg: string;
    productName: string;
    productDescription:string;
    productDilution:string;
}
type ProductDisplayerProps = {
    products: Array<CardProps>;
}

export function ProductDisplayer(props:ProductDisplayerProps){
    return(
        <div className="containerin">
            <div className="overflow">
                {props.products.map((product,index) =>{
                    if(index%2 == 0){
                        return(
                            <Card
                                direction="" 
                                productImg={product.productImg} 
                                productName={product.productName}
                                productDescription={product.productDescription}
                                productDilution={product.productDilution}
                            />     
                            
                        );
                    }else{
                        return(
                            <Card
                                direction="" 
                                productImg={product.productImg} 
                                productName={product.productName}
                                productDescription={product.productDescription}
                                productDilution={product.productDilution}
                            />
                        );
                        
                    }
                })}
            </div>
        </div>
    );
}