import { BrowserRouter, Route } from "react-router-dom";
import { ProductDisplayer } from "./componets/ProductDisplayer";
import { Automotivo } from "./pages/Automotivo";
import { Desinfetantes } from './pages/Desinfetantes';
import { Detergentes } from "./pages/Detergentes";
import { Home } from "./pages/Home";
import { Lavanderia } from "./pages/Lavanderia";


function App() {
  return (
    <BrowserRouter>
      <Route path="/" exact component={Home}/>
      <Route path="/desinfetantes" component={Desinfetantes}/>
      <Route path="/detergentes" component={Detergentes}/>
      <Route path="/lavanderia" component={Lavanderia}/>
      <Route path="/automotivo" component={Automotivo}/>
    </BrowserRouter>
  );
}

export default App;
